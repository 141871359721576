@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.car-detail {
    color: #525f7f;
    font-family: 'Poppins', 'Rubik', 'San serif' !important;
    font-size: 13px;
}
.car-detail .badge {
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 400;
}
.car-detail .badge.black {
    background-color: #263456;
    color: #FFF;
}
.car-detail .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}
.car-detail-container .badge.danger {
    background-color: #FF0000;
    color: #FFF;
}
.car-detail-container .badge.warning {
    background-color: #FFA500;
    color: #FFF;
}
.car-document-box {
    padding: 1.25rem 0.25rem;
}
.car-document-box .document-main {
    background-color: #D6D6D6;
    box-shadow: 3px 4px 12px 3px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 20px 7px 10px 7px;
    margin: 0rem;
}
.car-document-box .document-main:hover {
    cursor: pointer;
}
.car-document-box .document-main.active {
    background-color: #2DAFD3;
}
.car-document-box .document-popup a {
    cursor: pointer;
    color: #007bff;
}
.car-document-box .document-popup a:hover {
    color: #007bff;
    text-decoration: underline;
}
.car-document-box .document-main label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}
.car-document-box .document-main .row {
    margin: 0px;
}
.car-document-box .document-main .row p:nth-child(2) {
    margin-left: auto;
}
.car-document-box .document-main .fa-file-pdf-o, .fa-picture-o {
    cursor: default;
    margin-left: auto;
}
.car-document-box .document-main .selected-document {
    color: #fff
}
.car-document-box .document-main .not-selected-document {
    color: #000;
}
.documents-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.documents-tab {
    width: 100%;
    max-height: 800px;
    overflow-y: auto;
    background-color: #FFFFFF;
    padding: 2.5rem 2.8rem;
    margin-bottom: 2rem;
}
.documents-tab::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.documents-tab::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.documents-tab::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.agreement-detail .detailSection {
    padding: 10px 20px;
}
.pricing-tab-container, .equipments-tab {
    width: 100%;
    max-height: 55rem;
    background-color: #FFFFFF;
    padding: 2rem;
    margin-bottom: 2rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.insurance-tab-container table {
    width: 100%;
}
.pricing-tab-container table thead, .equipments-tab table thead, .insurance-tab-container table thead {
    background-color: #F0F0EB;
    color: #000000;
}
.pricing-tab-container table th, .equipments-tab table th, .insurance-tab-container table th {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
    font-size: 15px;
    font-weight: 500;
}
.pricing-tab-container table tr, .equipments-tab table tr, .insurance-tab-container table tr {
    border-bottom: 1px solid hsla(0, 0%, 79.2%, .21);
    margin: 10px;
}
.pricing-tab-container::-webkit-scrollbar, .equipments-tab::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.pricing-tab-container::-webkit-scrollbar-track, .equipments-tab::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.pricing-tab-container::-webkit-scrollbar-thumb, .equipments-tab::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.cardRow {
    padding: 20px;
}
.car-detail .danger {
    color: #FF5661
}
.car-detail .success {
    color: #02BD7D
}
.car-detail .black {
    color: #263456
}
.car-detail a.ghost, .car-detail .btn.ghost {
    background-color: transparent;
    color: #525f7f;
    border: 1px solid #525f7f;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
}
.car-detail .btn.primary {
    background-color: #525f7f;
    color: #FFF;
    border-color: transparent;
}
.car-detail .btn.success {
    background-color: #02BD7D;
    color: #FFF;
    border-color: transparent;
}
.car-detail-container .info-container {
    background-color: #F3F4F6;
    /* background-color: #F0F0EB; */
    /* border: 1px solid #D6D6D6; */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -webkit-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -moz-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
}
.car-detail-container .info-container:nth-child(1) {
    margin-bottom: 2rem;
}
.car-detail-container .enable-disable {
    max-height: 25vh;
    overflow: auto;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}
.tab-container .car-rental-container {
    background-color: #F0F0EB;
    border: 1px solid #D6D6D6;
    padding: 2rem;
    /* box-shadow: 3px 4px 12px 3px rgba(0, 0, 0, 0.07); */
    box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 2rem;
}
.car-header {
    font-family: 'Poppins';
    display: flex;
    background-color: #E0E0E0;
    color: black;
    margin-bottom: 54px;
    padding: 0px 50px 10px 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.car-header .btn.ghost:hover, .car-header .btn.ghost:focus, .car-header .btn.ghost:active {
    background-color: #263456;
    color: #FFF;
    border-color: transparent;
}
.car-header .btn.ghost {
    background-color: transparent;
    color: #525f7f;
    border: 1px solid #525f7f;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
}
.car-header div {
    align-self: flex-end;
}
.car-header .car-image .fa {
    border: 7px solid #f2f3f6;
    padding: 17px;
    border-radius: 50%;
    position: relative;
    bottom: -47px;
    background-color: #C6CBDE;
}
.car-header .status-stage .badge {
    font-size: 0.65em;
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 400;
}
.car-header .status-stage .badge.black {
    background-color: #263456;
    color: #FFF;
}
.car-header .status-stage .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}
.car-header .car-id-wrapper {
    background-color: #2DAFD3;
    color: white;
    top: 15%;
    left: 0%;
    padding: 0.25rem 0.75rem 0.25rem 1.5rem;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    position: absolute;
}
.car-header .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}
.car-header .status-stage .badge.danger {
    background-color: #FF5661;
    color: #FFF;
}
.car-header .badge.black {
    background-color: #263456;
    color: #FFF;
}
.car-header .status-stage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.car-header .header-detail {
    font-size: 14px;
    margin-bottom: 0;
}
.car-header .reserve-car {
    cursor: pointer;
}
.car-header .car-name small,
.car-header .car-name {
    margin-bottom: 0;
    font-size: 14px !important;
}
.car-header .car-name label {
    font-size: 24px !important;
    font-weight: 500;
    margin-bottom: 0;
}
.zero-padding .agreement-detail .car-rental-header {
    background-color: #2DAFD3;
    text-align: left;
    height: 45px;
    color: white;
    padding: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}
.zero-padding .agreement-detail .rental-pricing {
    background-color: #F0F0EB;
    border: 1px solid #D6D6D6;
    box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 180px;
    min-height: 180px;
    overflow-x: hidden;
    overflow-y: auto;
}
.zero-padding .agreement-detail .rental-pricing::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.zero-padding .agreement-detail .rental-pricing::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.zero-padding .agreement-detail .rental-pricing::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.zero-padding .agreement-detail .rental-pricing .row {
    margin-bottom: 0.8rem;
}
.zero-padding .agreement-detail > .row {
    margin-bottom: 1.5rem
}
.zero-padding .rental-pricing .activePrice {
    background-color: #008000;
    color: #ffffff;
}
.car-detail-container .tab-container {
    width: 100%;
    background-color: #FFFFFF;
    padding: 2.5rem;
    border: 1px solid #DADADA;
    min-height: 150px;
}
.car-detail-container .tab-container .car-rental-header {
    background-color: #2DAFD3;
    color: white;
    font-size: 16px !important;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
}
.car-detail-container .tab-container .info-container button {
    background-color: #263456;
    border-radius: 6px;
    padding: 6px 10px;
}
.car-detail-container .tab-container .info-container button:hover {
    background-color: #f2f3f6;
    color: #000000
}
.car-detail-container .tab-container .car-rental-container .car-rental {
    background-color: #FFFFFF;
    padding: 1.2rem 2.5rem;
    border-radius: 10px;
}
.car-detail-container .tab-container .info-container .data-wrapper {
    position: relative;
}
.car-detail-container .tab-container .info-container .data-wrapper .edit-value {
    position: absolute;
    top: 10px;
    right: 10px;
}
.car-detail-container .tab-container .info-container .data-wrapper .edit-actions-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
}
.car-detail-container .tab-container .info-container .tracker-actions {
    margin: 0px;
    padding: 0px;
}
.car-detail-container .tab-container .tracker-wrapper {
    margin: 0rem 0rem;
}
.tab-container .info-container .tracker-actions li {
    display: inline-block;
    padding-right: 10px;
    text-align: center;
    text-transform: uppercase;
}
.car-detail .nav-item {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "Poppins";
    color: #000000;
    text-align: center;
    margin-right: 0.75rem;
    border: 1px solid #DADADA;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #F0F0EB;
}
.car-detail .nav-tabs {
    border: none;
}
.car-detail .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 2.25rem !important;
}
.car-detail .nav-item:nth-child(n+2):after {
    margin-left: 2rem;
}
.rental-container, .inspection-container {
    width: 100%;
    min-height: 22vh;
    max-height: 75vh;
    background-color: #FFFFFF;
    padding: 1rem 2rem 0rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.rental-container h5, .inspection-container h5 {
    color: #000000;
}
.rental-container .rental-overview {
    margin-bottom: 1rem
}
.rental-container .agreements-list, .inspection-container .inspections-list {
    overflow: auto;
    /* height: 70vh; */
    padding: 0px;
}
.rental-container .agreements-list .more-agreements, .inspection-container .inspections-list, .more-inspections {
    position: relative;
    /* margin-top: 10px; */
    cursor: pointer;
}
.rental-container .agreements-list a {
    position: absolute;
    right: 0;
}
.rental-container .agreements-list a:hover, .rental-container .agreements-list a:active, .rental-container .agreements-list a:focus {
    opacity: 1;
    font-weight: 500;
}
.rental-container .agreements-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.rental-container .agreements-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.rental-container .agreements-list::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.rental-container .agreement-detail, .inspection-container .inspection-detail {
    border: 1px solid #D6D6D6;
    border-left: none;
    padding: 2.5rem 5rem;
    overflow: auto;
    min-height: 65vh;
    max-height: 70vh;
}
.inspection-container .inspection-detail table {
    width: 100%;
}
.inspection-container .inspection-detail table th, table td {
    border: none;
}
.rental-container .agreement-detail::-webkit-scrollbar, .inspection-container .inspection-detail::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.rental-container .agreement-detail::-webkit-scrollbar-track, .inspection-container .inspection-detail::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.rental-container .agreement-detail::-webkit-scrollbar-thumb, .inspection-container .inspection-detail::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.rental-container .agreement-detail hr, .inspection-container .inspection-detail hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
.car-detail .tab-container .car-deal-nav {
    display: flex;
    flex-direction: row;
}
.rental-container .agreement-detail .row .navRow h4, .car-detail-container .tab-container .car-deal-nav h4, .inspection-container .inspection-detail .row .navRow h4 {
    display: inline-block;
    padding-right: 25px;
    margin: 0;
    opacity: .8;
    cursor: pointer;
}
.rental-container .agreement-detail .row .navRow h4.active, .car-detail-container .tab-container .car-deal-nav h4.active, .inspection-container .inspection-detail .row .navRow h4.active {
    opacity: 1;
    font-weight: 500;
}
.rental-container .agreement-detail .row .navRow h4:hover, .car-detail-container .tab-container .car-deal-nav h4:hover, .rental-container .agreement-detail .row .navRow h4:active, .car-detail-container .tab-container .car-deal-nav h4:active, .rental-container .agreement-detail .row .navRow h4:focus, .car-detail-container .tab-container .car-deal-nav h4:focus {
    opacity: 1;
    font-weight: 500;
}
.rental-container .agreement-detail .info-container .email {
    word-wrap: break-word;
}
.rental-container .car-agreement, .inspection-container .car-inspection {
    border-bottom: 1px solid #D6D6D6;
    padding: 0.5rem 1.5rem;
    display: flex;
    flex-direction: row;
}
.rental-container .car-agreement.active, .inspection-container .car-inspection.active,
.rental-container .car-agreement:focus, .inspection-container .car-inspection:focus,
.rental-container .car-agreement:hover, .inspection-container .car-inspection:hover {
    background-color: #F0F0EB !important;
}
.rental-container .car-agreement span, .inspection-container .car-inspection span {
    align-self: center;
}
.rental-container .car-agreement h4, .inspection-container .car-inspection h4 {
    font-family: "Poppins";
    padding-left: 1rem;
    font-weight: 500;
    padding-top: 0.35rem;
    max-width: 175px;
    font-size: 15px;
}
.info-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}
.tab-container .car-rental-container .car-rental label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000000;
}
.tab-container .carissue-open {
    background-color: transparent;
    color: #DC3546;
    display: block;
    width: 100%;
    text-align: left;
    padding: 15px 30px;
    text-transform: uppercase;
    border: 1px solid#DC3546;
    border-radius: 6px;
    font-weight: 500;
}
.tab-container .carissue-open:hover, .tab-container .carissue-open:focus, .tab-container .carissue-open:active {
    box-shadow: none;
    background-color: #dc354648 !important;
    border-color: #DC3546 !important;
}
.tab-container .carissue-open span {
    float: right;
    font-weight: 400;
}
.tab-container .carissue-open span strong {
    font-weight: 700;
}
.tab-container .carissue-closed {
    background-color: transparent;
    color: #198754;
    display: block;
    width: 100%;
    text-align: left;
    padding: 15px 30px;
    text-transform: uppercase;
    border: 1px solid #198754;
    border-radius: 6px;
}
.tab-container .carissue-closed:hover, .tab-container .carissue-closed:focus, .tab-container .carissue-closed:active {
    box-shadow: none;
    background-color: #1987543c !important;
    border-color: #198754 !important;
}
.tab-container .carissue-closed span {
    float: right;
    font-weight: 400;
}
.tab-container .carissue-closed span strong {
    font-weight: 700;
}
.rental-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000000;
}
.car-detail-container .tab-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000000;
}
.car-detail-container .tab-container .car-rental-container .car-rental button {
    background-color: #1B4D7E;
    margin: 0px;
    width: 15.4rem;
}
.car-detail-container .tab-container .info-container > .row {
    margin-bottom: 1rem;
}
.car-detail-container .tab-container .info-container .equipments {
    display: flex;
    flex-wrap: wrap
}
.car-detail-container .tab-container .info-container .status-stage {
    display: flex;
    flex-direction: column;
}
.car-detail-container .tab-container .info-container .row:last-child {
    margin-bottom: 0rem;
}
.car-detail-container .tab-container .info-container input[type="text"]:disabled {
    background-color: #FFFFFF;
}
.car-detail-container .tab-container .enable-disable-history {
    background-color: #F0F0EB;
    border: 1px solid #D6D6D6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    margin-bottom: 2rem;
    min-height: 155px;
    max-height: 185px;
    overflow: auto;
}
.car-detail-container .tab-container .enable-disable-history::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.car-detail-container .tab-container .enable-disable-history h5 {
    color: #000000;
}
.car-detail-container .tab-container .enable-disable-history::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.car-detail-container .tab-container .enable-disable-history::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.documents-tab .upload-document, .equipments-tab .install-equipment {
    border-radius: 12px;
    background-color: #1B4D7E;
    width: 180px;
    height: 45px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
}
.zero-padding {
    padding: 0px
}
.documents-tab .view-more {
    text-align: right;
    margin-right: 1rem;
}
.documents-tab .car-document-box .document-popup {
    margin: 0;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #E7E9EC;
    padding: 10px;
    justify-content: center;
    text-align: center;
}
.documents-tab .car-document-box .document-popup .document-button .remove-open {
    border-radius: 8px;
    height: 38px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    margin-right: 0px;
}
.documents-tab .car-document-box .document-popup .document-button .download {
    background-color: rgb(27, 77, 126);
}
.documents-tab .car-document-box .document-popup .document-button .remove {
    background-color: rgb(218, 218, 218);
    color: rgb(0, 0, 0);
}
.documents-tab .car-document-box .document-popup .document-button .open {
    background-color: #2DAFD3;
    color: rgb(0, 0, 0);
}
.documents-tab .car-document-box .document-popup .document-image {
    width: 300px;
    max-height: 200px;
    cursor: pointer;
    object-fit: contain;
}
.documents-tab .car-document-box .document-button {
    padding: 1rem
}
.car-detail .tab-container:last-child {
    margin-bottom: 0rem;
}
.car-detail .tab-container table {
    width: 100%;
}
.car-detail .tab-container table thead {
    background-color: #F0F0EB;
    color: #000000;
}
.car-detail .tab-container table tr {
    border: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
}
.car-detail .tab-container table th, table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}
.car-detail .tab-container table th {
    font-size: 15px;
    font-weight: 500;
}
.car-detail .tab-container table td {
    padding-left: 20px;
}
.rental-container table {
    width: 100%;
}
.rental-container .history-container table thead {
    background-color: #F0F0EB;
    color: #000000;
}
.rental-container .history-container .danger {
    color: #FF5661 !important;
}
.rental-container .history-container .success {
    color: #02BD7D !important;
}
.rental-container .history-container {
    max-height: 225px;
    overflow: auto;
}
.rental-container .history-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.rental-container .history-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.rental-container .history-container .table-responvsie {
    max-height: 250px;
    overflow: auto;
}
.rental-container .history-container .table-responvsie .limit-text {
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block
}
.rental-container .history-container .table-responvsie::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.rental-container .history-container .table-responvsie::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.rental-container .history-container .table-responvsie::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.rental-container .history-container::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.rental-container .rental-pricing-container table th, table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}
.rental-container .rental-pricing-container table th {
    font-size: 15px;
    font-weight: 500;
    background-color: #F0F0EB;
    color: #000000;
}
.rental-container .rental-pricing-container table td {
    padding-left: 20px;
}
.CarHeader {
    border-radius: 18px;
    border: 1px solid #E0E0E0;
    background: #E0E0E0;
    min-height: 20rem;
    height: 25rem;
    margin-bottom: 15px;
}
.car-header .car-image {
    align-self: baseline;
}
.car-detail .btn.ghost:hover, .car-detail .btn.ghost:focus, .car-detail .btn.ghost:active {
    background-color: #263456;
    color: #FFF;
    border-color: transparent;
}
.rentalContainer .car-agreement .btn.ghost:hover, .rentalContainer .car-agreement .btn.ghost:focus, .rentalContainer .car-agreement .btn.ghost:active {
    background-color: #525f7f;
    color: #FFF;
    border-color: transparent;
}
.toggle-switch {
    position: relative;
    width: 60px;
    padding: 0px 9px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    opacity: 0.85;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    color: white;
    font-weight: 500;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: "";
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #DC3546;
    color: #fff;
}
.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #ddd;
    cursor: not-allowed;
}
.equipments-tab .toggle-switch-inner:after, .toggle-switch-inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #2acd72;
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 10px;
}
.documents-tab .documents-container .document-popup .document-button .remove-open {
    border-radius: 8px;
    height: 38px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    margin-right: 0px;
}
.documents-tab .documents-container .document-popup .document-button .download {
    background-color: rgb(27, 77, 126);
}
.documents-tab .documents-container .document-popup .document-button .remove {
    background-color: rgb(218, 218, 218);
    color: rgb(0, 0, 0);
}
.documents-tab .documents-container .document-popup .document-button .open {
    background-color: #2DAFD3;
    color: rgb(0, 0, 0);
}
/* .confirmation-popup .modal-header {
    background-color: #1B4D7E;
    color: #fff;
}
.confirmation-popup .modal-content {
    padding: 0px;
}
.confirmation-popup .modal-footer button {
    width: 65px;
    font-size: 11px;
    font-weight: 700;
    height: 38px;
}
.confirmation-popup .modal-body p {
    font-size: 15px;
    font-weight: 500;
} */
.insurance-tab-container {
    width: 100%;
    background-color: #FFFFFF;
    padding: 2.5rem;
    border: 1px solid #DADADA;
    min-height: 150px;
}
.insurance-tab-container .active-price {
    background-color: #008000;
    color: #ffffff;
}
.confirm, .confirm:hover {
    background-color: #dc3545;
}
.cancel, .cancel:hover {
    background-color: rgb(27, 77, 126);
}
.car-detail-container .change-view {
    margin-bottom: 15px;
}
.rental-container .no-rentals {
    font-size: 18px;
    font-weight: 500;
}
.car-detail i.switch {
    font-size: 25px;
    border: none;
}
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.car-tracker-modal .modal-content .modal-body > .row:hover .selectButtonParent, .car-tracker-modal .modal-content .modal-body > .row:active .selectButtonParent {
    left: 0;
    opacity: 1;
}
.car-tracker-modal p {
    margin-top: .25rem;
    margin-bottom: .25rem;
    word-wrap: break-word;
    font-size: .8em;
}
.car-tracker-modal .label {
    font-size: .75em;
    opacity: .8;
}
.car-tracker-modal {
    color: #273846cc;
    font-family: 'Rubik', 'San serif';
}
.car-tracker-modal .modal-content {
    background-color: transparent;
}
.car-tracker-modal .modal-content .modal-body, .car-tracker-modal .modal-content .modal-footer {
    background-color: #fff;
}
.car-tracker-modal .modal-content .modal-header {
    font-weight: 500;
    background-color: #344A5C;
    color: #ffffff;
}
.car-tracker-modal .selectButtonParent {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;
    left: -101%;
    top: 0;
    opacity: 0;
    background-color: rgba(80, 97, 111, 0.68);
    z-index: 1;
}