/* # Document card container should have three cards in a row */
.DocumentCardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    margin: 0 0 20px 0;
}

.DocumentCard {
    width: calc(33.33% - 2em);
    margin: 0 2em 2em 0;
    box-sizing: border-box;
    padding: 15px 30px;
    background-color: rgb(230, 235, 242);
    border: none;
    border-radius: 20px;
    cursor: pointer;
}
/* on mobile DocumentCard width should be 100% */



.DocumentCardPreviewEmbed {
    vertical-align: middle;
    border-style: none;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden !important;
}

.DocumentCardPreview {
    margin: 20px 0;
    height: 200px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
}

.DocumentCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
}

.DocumentCardTitle {
    font-weight: 500;
    color: rgb(19, 23, 29);
}

.DocumentCardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    min-height: 20px;
}
.DocumentCardFooter .badge {
    font-size: 12px;
}
.modal-backdrop.show {
    opacity: 0.9;
}
.DocViewerModal .modal-content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.DocViewerModal.modal-dialog {
    width: 95vw;
    height: 95vh;
    margin: 0 auto;
    padding: 5vh;
    overflow: hidden;
    max-width: 100vw;
}

.DocViewerModal .modal-body {
    overflow: scroll;
}
.DocViewerModal .DocViewerFrame {
    width: 100%;
    min-height: 100%;
}
.DocViewerImage {
    margin: 0 auto;
    display: block;
    border-radius: 4px;
    max-height: 100vh;
}
.DocViewerModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    margin-bottom: 20px;
    padding: 0 1rem;
    border-bottom: 1px solid #dee2e6;
}

.DocViewerModalActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    gap: 20px;
}

.DocViewerModalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    margin-top: 20px;
    padding: 1rem 1rem;
    border-top: 1px solid #dee2e6;
}

.DocViewerFooterAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.3rem;
    gap: 20px;
}