.TransactionListNew .table-responsive {
    max-height: 500px;
    overflow-y: auto;
}
.TransactionListNew .table thead th {
    text-transform: uppercase;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.15px;
    position: "sticky";
    top: -1;
}
.TransactionListNew .no_decoration {
    color: #525f7f !important;
}
.TransactionListNew tr.success td {
    color: #02BD7D;
}
.TransactionListNew tr.danger td {
    color: #FF5661;
}
.TransactionListNew .table th, .table td {
    color: #525f7f;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    font-size: 14px !important;
}
.TransactionListNew .shortText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.TransactionListNew .table th, .table td {
    border: none;
}
.TransactionListNew .table tr th {
    opacity: .8;
}
.TransactionListNew .table tr {
    border-bottom: 1px solid rgba(187, 187, 187, 0.32);
}
.TransactionListNew .btn.btn-link {
    margin: 0;
    color: #525f7f;
    font-weight: 500;
    text-decoration: none;
}
.TransactionListNew .btn.ghost:focus, .TransactionListNew .btn.ghost:active {
    background-color: transparent;
    color: #525f7f;
    border-color: #525f7f;
}

.stripe-payment-modal .btn {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
}
.stripe-payment-modal .btn.btn-primary {
    background-color: #0B80F9;
}
.stripe-payment-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.stripe-payment-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.stripe-payment-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.stripe-payment-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.stripe-payment-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.stripe-payment-modal .modal-content small,
.stripe-payment-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.stripe-payment-modal .modal-content .modal-header {
    justify-content: center;
    text-transform: uppercase;
}
.stripe-payment-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.stripe-payment-modal .modal-content .issue-modal-jobs-wrapper .card {
    margin: 7px 0px;
}
.stripe-payment-modal .modal-content .issue-modal-jobs-wrapper .card-body {
    padding: 7px 15px;
    background-color: #0000000e;
}
.stripe-payment-modal .modal-content .issue-modal-jobs-wrapper .card-body .row {
    padding-top: 0.45rem;
}
.stripe-payment-modal .modal-content .issue-modal-jobs-wrapper label {
    margin-bottom: 0;
}
.stripe-payment-modal .modal-content .issue-modal-collapse-btn {
    width: 100%;
    border-radius: 0px;
    text-transform: uppercase;
    background-color: #0000008c;
    border: none;
}
.stripe-payment-modal .issue-extra-info-wrapper {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid #dee2e6;
}
.aggregate-info-preview {
    float: right;
    background-color: #f0f0eb;
    font-weight: 600;
}
.transaction-action-items ul {
    list-style-type: none;
    padding-left: 0;
}
.transaction-action-items ul li {
    min-width: 100px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-bottom: 1px solid #C0C8D2;
}
