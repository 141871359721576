.process-insurance-modal-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.process-insurance-modal-primary-btn {
    background-color: #303e67;
    width: 50% !important;
    color: #ffffff;
    height: 150px !important;
    font-size: large;
    border-radius: 10px;
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0.7;
    border: none;
}
#process-insurance-modal-popover {
    width: 100%;
    margin-bottom: 10px;
    border: solid 1px #303e67;
}
#process-insurance-modal-popover-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
#process-insurance-modal-popover-body {
    font-size: 13px;
}
.process-insurance-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.process-insurance-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.process-insurance-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}